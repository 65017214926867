@import '../../../../../node_modules/@cian/ui-kit/colors/variables.module.css';

.slide {
  width: 216px;
  flex-shrink: 0;
}

.card {
  all: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 216px;
  height: 100%;
  cursor: pointer;
  background: white;
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px var(--gray10_100, #e8e9ec);
  flex: 0 0 auto;
  scroll-snap-align: start;
}

.image {
  display: block;
  overflow: hidden;
  max-width: 100%;
  background-color: var(--gray10_100, #e8e9ec);
  border-top: 1px solid var(--gray10_100, #e8e9ec);
  border-left: 1px solid var(--gray10_100, #e8e9ec);
  border-right: 1px solid var(--gray10_100, #e8e9ec);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex: 0 0 140px;
  object-fit: cover;
}

.content {
  padding: 8px 8px 12px;
  display: flex;
  flex-direction: column;
}

.title > * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.details {
  display: flex;
  justify-content: start;
  align-items: center;
  /* flex-wrap: wrap; */
}

.reviews {
  padding-right: 4px;
  flex-shrink: 0;
  max-width: 100%;
}

.status {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
  flex-grow: 0;
}

.geo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
