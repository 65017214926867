@import '../../../../../node_modules/@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.section {
  padding: 24px 0;
  background: var(--surface-neutral-default, #f3f6ff);
  border-radius: 8px;
}

.header {
  padding: 0 20px;
  display: block;
}

.recommendations {
  padding: 16px 20px;
  display: flex;
  gap: 8px;
  overflow: scroll hidden;
  list-style: none;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 20px;
  scrollbar-width: none;
  pointer-events: auto;
}

.recommendations::-webkit-scrollbar {
  display: none;
}

.action {
  padding: 0 20px;
}
